import React from "react";

function About  () {

    return (
        <div>
            <h1>About page here!</h1>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, modi!
            </p>
        </div>
    );

}

export default About;