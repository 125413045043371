import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  Navigate,Link
} from "react-router-dom";
import About from './Component/About';
function App() {
  // const [page, SetPage] = useState(1);
  // var options = {  
  //   method: 'POST',
  //   headers: {
  //     'Accept': 'application/json',
  //     'Content-Type': 'application/json',
  //     'Origin': '',
  //     'Host': 'api.producthunt.com'
  //   },
  //   body: JSON.stringify({
  //     'client_id': '(API KEY)',
  //     'client_secret': '(API SECRET)',
  //     'grant_type': 'client_credentials'
  //   })
  // }
  const fetchData = () => {

    // var options = {  
    //   method: 'GET',
    //   headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json',
    //     'Origin': '',
    //     'Host': '54.176.157.176'
    //   },
    //   // body: JSON.stringify({"a":"df"})
    // }

    // fetch('http://54.176.157.176:1122/getuser', options)  
    // .then(function(res) {
    //   return res.json();
    //  })
    // .then(function(resJson) {
    //   console.log(resJson);
    //  })
    // fetch("https://reqres.in/api/users?page=1")
    fetch("http://54.176.157.176:1122/getuser")
          // headers: {
          //     'Content-type': 'application/json',
          //     // 'Authorization': `Bearer ${token}`, // notice the Bearer before your token
          // }
          // body: JSON.stringify({'a':'a'})
          .then(response => {
              // console.log(response);
              return response.json()
          })
          .then(data => {
              console.log(data);
              // setUsers(data.data)
          });
        // ;
}

useEffect(() => {
    fetchData()
}, []);
  return (
    <div className="App">
      <Routes>
          <Route
            exact
            path="/about"
            element={<About title={"About"} />}
          ></Route>
        </Routes>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Welcome to the our site</h1>
        <ul className="navigation clearfix home-menu">
          <Link to="/about">Go to About Page</Link>
        </ul>
      </header> 
    </div>
  );
}

export default App;
